import styled from "styled-components/macro";
import React from "react";
import PropTypes from "prop-types";
import Colors from "./colorswatches";

const WellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid ${Colors.darkGrey700};
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div``;

const Well = ({ children }) => {
  return (
    <WellContainer>
      <ContentContainer>{children}</ContentContainer>
    </WellContainer>
  );
};

Well.propTypes = {
  children: PropTypes.node,
};

export default Well;
