import React from "react";
import InlineLoader from "./inline_loader";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const LoadingMessageContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

const LoadingMessage = ({ message }) => {
  return (
    <LoadingMessageContainer>
      <InlineLoader />
      {message}
    </LoadingMessageContainer>
  );
};

LoadingMessage.propTypes = {
  message: PropTypes.string,
};

export default LoadingMessage;
