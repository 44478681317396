import styled, { css } from "styled-components";
import Colors from "./colorswatches";

const Link = styled.a`
  cursor: pointer;

  ${({ type }) => {
    switch (type) {
      case "danger":
        return css`
          color: ${Colors.red200};

          &:hover {
            color: ${Colors.red300};
          }
        `;
    }
  }}
`;

export default Link;
